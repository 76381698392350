import * as  SecureLS from 'secure-ls';
const ls = new SecureLS();

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID 
export const GMAP_KEY = process.env.REACT_APP_GMAP_KEY_ID
export const API_AUTH = process.env.REACT_APP_AUTH0_URL
export const CONNECTION_DB = process.env.REACT_APP_CONNECTION_DB
export const API_GRAPHQL = process.env.REACT_APP_PUBLIC_PATH

export const tokenUser = ls.get('_tKn_iD').data
export const userID = ls.get('_uSr_iD').data
export const userRoles = ls.get('_rLs').data

export const API_REPORT = process.env.REACT_APP_API_REPORT
export const API_REPORT_ATTND = process.env.REACT_APP_API_REPORT_ATTND
export const API_HASURA = process.env.REACT_APP_HASURA_API
export const HASURA_SECRET = process.env.REACT_APP_HASURA_SECRET

// export const decoded = ls.get('_jWt_dec').data !== undefined ? JSON.parse(ls.get('_jWt_dec').data) : null

// console.log(decoded, 'decoded broo')