import {useState, useEffect} from "react"
import Axios from 'axios'; 
import { API_GRAPHQL, tokenUser } from '../config.js'
import swal from 'sweetalert';
import * as SecureLS from 'secure-ls';

const ls = new SecureLS();

const useFetch = () => {
  
  const [dataProfile, setDataProfile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

    useEffect(() => { 
      const loadProfile = async () => {
        setLoading(true)
        let id_user = ls.get('_uSr_iD').data    
          try {    
            const response = await Axios({
              url: API_GRAPHQL,
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${tokenUser}`
              },
              data: {
                query: `
                  query loadProfile ($id: Int!) {
                    phoenix_mono_profiles(where: {user_id: {_eq: $id}}) {
                      user_id
                      birthday
                      address
                      firstname
                      gender
                      id
                      jobs
                      lastname
                      phone
                      urlImage
                      user {
                        name
                      }
                    }
                  }`,
                  variables: {
                    id : id_user
                  }
                }
              })
            if(response) {
              setDataProfile(response.data.data.phoenix_mono_profiles)
              localStorage.setItem('name', response.data.data.phoenix_mono_profiles[0].user.name)
              setLoading(false)
              setLoaded(true)
            }       
          } catch (error) {
            setLoading(false)
            swal("Error!", `${error}`, "error");
          }          
      }
      loadProfile()
    
    }, []);

    return { loading, dataProfile, loaded }
}

export default useFetch;