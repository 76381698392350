import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu, 
  DropdownItem,
  DropdownToggle
} from "reactstrap"
import * as Icon from "react-feather"
import { history } from "../../../history"
import "../../../assets/scss/pages/account-settings.scss"
import useFetchProfile from './../../../utils/useFetchProfile'
import { gql, useMutation } from '@apollo/client';
import swal from 'sweetalert'
import moment from 'moment'

const UPDATE_DATALOGIN = gql`
  mutation insertDataLogin($date: String!, $status: String!, $id: bigint! ){
    update_phoenix_mono_users_online_by_pk(pk_columns: {id: $id}, _set: {date_out: $date status: "Offline"}) {
      status
      date_out
    }
  }`;

const NavbarUser = () => {
  const { loading, loaded, dataProfile } = useFetchProfile()

  const [updateDataLogin] = useMutation(UPDATE_DATALOGIN);

  const onClickLogOut = async() => {
    try {
      const user_online = await updateDataLogin({ variables: { 
          date : moment().format(),
          status: "Offline",
          id: localStorage.getItem('id_session')
        }
      });
      if(user_online) {
        console.log('logout success')
      }
      localStorage.clear()
      history.push("/")
    } catch (error) {
      swal("Error!", `${error}`, "error");
    }
    
  }

  const UserDropdown = () => {
    return (
      <DropdownMenu right>
        <DropdownItem tag="a" href="/dashboard/account-setting">
          <Icon.User size={14} className="mr-50" />
          <span className="align-middle">My Profile</span>
        </DropdownItem>      
        <DropdownItem divider />
        <DropdownItem
          tag="a"
          href="#"
          onClick={onClickLogOut}
        >
          <Icon.Power size={14} className="mr-50" />
          <span className="align-middle">Log Out</span>
        </DropdownItem>
      </DropdownMenu>
    )
  }

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none" style={{color: 'white'}}>
              <span className="user-name text-bold-600">
              {loaded && dataProfile[0].user.name}
              </span>
              <span className="user-status">
              {loaded && dataProfile[0].jobs}
              </span>
            </div>
              {loading ? 
              <span data-tour="user">
                  <img
                  src="https://phoenix-storage.nyc3.cdn.digitaloceanspaces.com/avatarmale.png"
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                /> 
              </span> : 
              <span data-tour="user">
                { loaded && dataProfile[0].urlImage === null ? 
                <img
                  src="https://phoenix-storage.nyc3.cdn.digitaloceanspaces.com/avatarmale.png"
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                /> : 
                <img
                  src={loaded ? dataProfile[0].urlImage : ""}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                /> } 
              </span>
              }
          </DropdownToggle>
          <UserDropdown />
        </UncontrolledDropdown>
        
      </ul>
    )
}

export default NavbarUser
