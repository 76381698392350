import React from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import SideMenuGroup from "./SideMenuGroup"
import { Badge } from "reactstrap"
import { ChevronRight } from "react-feather"
import { history } from "../../../../../history"
import swal from 'sweetalert'
import { API_GRAPHQL, tokenUser } from './../../../../../config'
import Axios from 'axios'; 
import * as  SecureLS from 'secure-ls';

const ls = new SecureLS();
let roles = ls.get('_rLs')

class SideMenuContent extends React.Component {
  constructor(props) {
    super(props)
    
    this.parentArr = []
    this.collapsedPath = null
    this.redirectUnauthorized = () => {
      history.push("/not-authorized")
    }
  }
  state = {
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
    navigation : [],
    loaded: false
  }

  
  handleGroupClick = (id, parent = null, type = "") => {
    let open_group = this.state.activeGroups
    let active_group = this.state.currentActiveGroup
    let temp_arr = this.state.tempArr
    // Active Group to apply sidebar-group-active class
    if (type === "item" && parent === null) {
      active_group = []
      temp_arr = []
    } else if (type === "item" && parent !== null) {
      active_group = []
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length)
      } else {
        temp_arr = []
        temp_arr.push(parent)
      }
      active_group = temp_arr.slice(0)
    } else if (type === "collapse" && parent === null) {
      temp_arr = []
      temp_arr.push(id)
    } else if (type === "collapse" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0)
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length)
      } else {
        temp_arr.push(id)
      }
    } else {
      temp_arr = []
    }

    if (type === "collapse") {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function(obj) {
          return active_group.indexOf(obj) === -1
        })
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function(obj) {
            return !temp.includes(obj)
          })
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0)
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id)
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1)
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0)
    }

    this.setState({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group
    })
  }

  initRender = parentArr => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false
    })
  }

  loadMenu = async () => {      
    try {    
      // console.log('roles', roles.data)
      const response = await Axios({
        url: API_GRAPHQL,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${tokenUser}`
        },
        data: {
          query: `
            query loadMenu ($ilike: String!) {
              phoenix_mono_navigate (order_by: {created_at: asc}, where: {parent_id: {_is_null: true}, hasRoles: {navigate: {hasRoles: {roles: {value: {_ilike: $ilike}}}}}}) {
                navigate_group {
                  grouptitle
                  id
                  type
                }
                id
                name
                type
                icons
                url
                hasRoles {
                  roles {
                    value
                  }
                }
                child {
                  name
                  id
                  icons
                  type
                  url
                }
              }
            }`, 
            variables : {
              ilike : roles.data
            }
          }
      })
      if(response) {
        let dataValue = response.data.data.phoenix_mono_navigate
        let state = []
        // console.log(dataValue , 'dataValue')
        state.push({
          id: "0",
          title: "Dashboard",
          type: "item",
          icon: `${process.env.REACT_APP_S3_CDN_URL}../home.svg`,
          navLink: "/dashboard"
        }) 

        for(var aa = 0; aa < dataValue.length; aa++ ) {
          if(dataValue[aa].navigate_group.grouptitle === "Menu Tasks"){
            state.push({
              type: "groupHeader",
              groupTitle: "MENU TASKS"
            }) 
            break;
          } 
        }
        

        for(var i = 0; i < dataValue.length; i++ ) {
          let rolesParent = dataValue[i].hasRoles 
          let stateRolesParent = []
          for(var m = 0; m < rolesParent.length; m++) {
            // roles child
            stateRolesParent.push(rolesParent[m].roles.value.toUpperCase());                        
          }
          if(dataValue[i].navigate_group.grouptitle === "Menu Tasks") {
            if(dataValue[i].child.length !== 0) {
              let stateChild = []
              let child = dataValue[i].child
              for(var k = 0; k < child.length; k++) {
                // child menu
                stateChild.push({ 
                  id: child[k].id, 
                  title: child[k].name,
                  type: "item",
                  icon: child[k].icons,
                  permissions: stateRolesParent,
                  navLink: child[k].url
                });                        
              }
              
                // parent menu has a child
                state.push({ 
                  id: dataValue[i].id,
                  title: dataValue[i].name,
                  type: "collapse",
                  icon: dataValue[i].icons,
                  badge: "warning",
                  permissions: stateRolesParent,
                  children : stateChild
                });  
            } else {
              // parent menu does not have a child
              state.push({ 
                id: dataValue[i].id,
                title: dataValue[i].name,
                type: "item",
                icon: dataValue[i].icons,
                permissions: stateRolesParent,
                navLink: dataValue[i].url
              });    
            }
          } 
        }

        for(var ab = 0; ab < dataValue.length; ab++ ) {
          if(dataValue[ab].navigate_group.grouptitle === "Menu Users"){
            state.push({
              type: "groupHeader",
              groupTitle: "MENU USERS"
            })
            break;
          } 
        }
        
        for(var a = 0; a < dataValue.length; a++ ) {
          let rolesParent = dataValue[a].hasRoles 
          let stateRolesParent = []
          for(var u = 0; u < rolesParent.length; u++) {
            // roles child
            stateRolesParent.push(rolesParent[u].roles.value.toUpperCase());                        
          }
          if(dataValue[a].navigate_group.grouptitle === "Menu Users") {
             
            if(dataValue[a].child.length !== 0) {
              let stateChild = []
              let child = dataValue[a].child
              for(var c = 0; c < child.length; c++) {
                // child menu
                stateChild.push({ 
                  id: child[c].id,
                  title: child[c].name,
                  type: "item",
                  icon: child[c].icons,
                  permissions: stateRolesParent,
                  navLink: child[c].url
                });                        
              }
              
                // parent menu has a child
                state.push({ 
                  id: dataValue[a].id,
                  title: dataValue[a].name,
                  type: "collapse",
                  icon: dataValue[a].icons,
                  badge: "warning",
                  permissions: stateRolesParent,
                  children : stateChild
                });  
            } else {
              // parent menu does not have a child
              state.push({ 
                id: dataValue[a].id,
                title: dataValue[a].name,
                type: "item",
                icon: dataValue[a].icons,
                permissions: stateRolesParent,
                navLink: dataValue[a].url
              });    
            }
          } 
        }
        for(var ss = 0; ss < dataValue.length; ss++ ) {
          if(dataValue[ss].navigate_group.grouptitle === "Menu Items"){
            state.push({
              type: "groupHeader",
              groupTitle: "MENU ITEMS"
            })
            break;
          } 
        }
         

        for(var b = 0; b < dataValue.length; b++ ) {
          if(dataValue[b].navigate_group.grouptitle === "Menu Items") {
            let rolesParent = dataValue[b].hasRoles 
            let stateRolesParent = []
            for(var w = 0; w < rolesParent.length; w++) {
              // roles child
              stateRolesParent.push(rolesParent[w].roles.value.toUpperCase());                        
            }
            if(dataValue[b].child.length !== 0) {
              let child = dataValue[b].child
              let stateChild = []
              for(var d = 0; d < child.length; d++) {
                // child menu
                stateChild.push({ 
                  id: child[d].id,
                  title: child[d].name,
                  type: "item",
                  icon: child[d].icons,
                  permissions: stateRolesParent,
                  navLink: child[d].url
                });                        
              }
              // parent menu has a child
              state.push({ 
                id: dataValue[b].id,
                title: dataValue[b].name,
                type: "collapse",
                icon: dataValue[b].icons,
                permissions: stateRolesParent,
                children : stateChild
              });  
            } else {
              // parent menu does not have a childa
              state.push({ 
                id: dataValue[b].id,
                title: dataValue[b].name,
                type: "item",
                icon: dataValue[b].icons,
                permissions: stateRolesParent,
                navLink: dataValue[b].url
              });    
            }
          } 
        }

        for(var dd = 0; dd < dataValue.length; dd++ ) {
          if(dataValue[dd].navigate_group.grouptitle === "Menu Stores"){
            state.push({
              type: "groupHeader",
              groupTitle: "MENU STORES"
            }) 
            break;
          } 
        }
        

        for(var f = 0; f < dataValue.length; f++ ) {
          let rolesParent = dataValue[f].hasRoles 
          let stateRolesParent = []
          for(var r = 0; r < rolesParent.length; r++) {
            // roles child
            stateRolesParent.push(rolesParent[r].roles.value.toUpperCase());                        
          }
          if(dataValue[f].navigate_group.grouptitle === "Menu Stores") {
            if(dataValue[f].child.length !== 0) {
              let stateChild = []
              let child = dataValue[f].child
              for(var g = 0; g < child.length; g++) {
                // child menu
                stateChild.push({ 
                  id: child[g].id,
                  title: child[g].name,
                  type: "item",
                  icon: child[g].icons,
                  permissions: stateRolesParent,
                  navLink: child[g].url
                });                        
              }
              
                // parent menu has a child
                state.push({ 
                  id: dataValue[f].id,
                  title: dataValue[f].name,
                  type: "collapse",
                  icon: dataValue[f].icons,
                  badge: "warning",
                  permissions: stateRolesParent,
                  children : stateChild
                });  
            } else {
              // parent menu does not have a child
              state.push({ 
                id: dataValue[f].id,
                title: dataValue[f].name,
                type: "item",
                icon: dataValue[f].icons,
                permissions: stateRolesParent,
                navLink: dataValue[f].url
              });    
            }
          } 
        }

        for(var bb = 0; bb < dataValue.length; bb++ ) {
          if(dataValue[bb].navigate_group.grouptitle === "Others"){
            state.push({
              type: "groupHeader",
              groupTitle: "OTHERS"
            }) 
            break;
          } 
        }
       

        for(var o = 0; o < dataValue.length; o++ ) {
          let rolesParent = dataValue[o].hasRoles 
          let stateRolesParent = []
          for(var t = 0; t < rolesParent.length; t++) {
            // roles child
            stateRolesParent.push(rolesParent[t].roles.value.toUpperCase());                        
          }
          if(dataValue[o].navigate_group.grouptitle === "Others") {
            if(dataValue[o].child.length !== 0) {
              let stateChild = []
              let child = dataValue[o].child
              for(var j = 0; j < child.length; j++) {
                // child menu
                stateChild.push({ 
                  id: child[j].id,
                  title: child[j].name,
                  type: "item",
                  icon: child[j].icons,
                  permissions: stateRolesParent,
                  navLink: child[j].url
                });                        
              }
              
                // parent menu has a child
                state.push({ 
                  id: dataValue[o].id,
                  title: dataValue[o].name,
                  type: "collapse",
                  icon: dataValue[o].icons,
                  badge: "warning",
                  permissions: stateRolesParent,
                  children : stateChild
                });  
            } else {
              // parent menu does not have a child
              state.push({ 
                id: dataValue[o].id,
                title: dataValue[o].name,
                type: "item",
                icon: dataValue[o].icons,
                permissions: stateRolesParent,
                navLink: dataValue[o].url
              });    
            }
          } 
        }

        for(var ac = 0; ac < dataValue.length; ac++ ) {
          if(dataValue[ac].navigate_group.grouptitle === "Settings"){
            state.push({
              type: "groupHeader",
              groupTitle: "SETTINGS"
            }) 
            break;
          } 
        }
        

        for(var e = 0; e < dataValue.length; e++ ) {
          let rolesParent = dataValue[e].hasRoles 
          let stateRolesParent = []
          for(var y = 0; y < rolesParent.length; y++) {
            // roles child
            stateRolesParent.push(rolesParent[y].roles.value.toUpperCase());                        
          }
          if(dataValue[e].navigate_group.grouptitle === "Settings") {
            if(dataValue[e].child.length !== 0) {
              let stateChild = []
              let child = dataValue[e].child
              for(var p = 0; p < child.length; p++) {
                // child menu
                stateChild.push({ 
                  id: child[p].id,
                  title: child[p].name,
                  type: "item",
                  icon: child[p].icons,
                  permissions: stateRolesParent,
                  navLink: child[p].url
                });                        
              }
              
                // parent menu has a child
                state.push({ 
                  id: dataValue[e].id,
                  title: dataValue[e].name,
                  type: "collapse",
                  icon: dataValue[e].icons,
                  badge: "warning",
                  permissions: stateRolesParent,
                  children : stateChild
                });  
            } else {
              // parent menu does not have a child
              state.push({ 
                id: dataValue[e].id,
                title: dataValue[e].name,
                type: "item",
                icon: dataValue[e].icons,
                permissions: stateRolesParent,
                navLink: dataValue[e].url
              });    
            }
          } 
        }

        // console.log(state, 'state for loop')
        this.setState({
          navigation: state,
          loaded : true
        })
        localStorage.setItem("menu_content", JSON.stringify(state))
      }       
    } catch (error) {
        swal("Error!", `${error}`, "error");
    }          
  }



  componentDidMount() {
    this.initRender(this.parentArr[0] ? this.parentArr[0] : [])
    if(localStorage.getItem("menu_content")) {
      this.setState({
        navigation: JSON.parse(localStorage.getItem("menu_content")),
        loaded : true
      })
    } else {
      this.loadMenu()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths)
      }

      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      )
    }
  }

  render() {
    // Loop over sidebar items
    // eslint-disable-next-line
    // console.log(navigationConfig, 'navigationConfig')
    // console.log(this.state, 'statee')

    if(this.state.loaded) {
      const menuItems = this.state.navigation.map(item => {
        //  eslint-disable-line
        const CustomAnchorTag = item.type === "external-link" ? `a` : Link
        // checks if item has groupheader
        if (item.type === "groupHeader") {
          return ( 
            <li
              className="navigation-header"
              key={`group-header-${item.groupTitle}`}
            >
            <span>{item.groupTitle}</span>
          </li>
          )
        }
  
        let renderItem = (
          <li
            className={classnames("nav-item", {
              "has-sub": item.type === "collapse",
              open: this.state.activeGroups.includes(item.id),
              "sidebar-group-active": this.state.currentActiveGroup.includes(
                item.id
              ),
              hover: this.props.hoverIndex === item.id,
              active:
                (this.props.activeItemState === item.navLink &&
                item.type === "item" )|| (item.parentOf && item.parentOf.includes(this.props.activeItemState)),
              disabled: item.disabled
            })}
            key={item.id}
            onClick={e => {
              e.stopPropagation()
              if (item.type === "item") {
                this.props.handleActiveItem(item.navLink)
                this.handleGroupClick(item.id, null, item.type)
                if(this.props.deviceWidth <= 1200 && item.type === "item"){
                  this.props.toggleMenu()
                }
              } else {
                this.handleGroupClick(item.id, null, item.type)
              }
            }}
          >
            <CustomAnchorTag
              to={item.filterBase ? item.filterBase : item.navLink && item.type === "item" ? item.navLink :  ""}
              href={item.type === "external-link" ? item.navLink : ""}
              className={`d-flex ${
                item.badgeText
                  ? "justify-content-between"
                  : "justify-content-start"
              }`}
              onMouseEnter={() => {
                this.props.handleSidebarMouseEnter(item.id)
              }}
              onMouseLeave={() => {
                this.props.handleSidebarMouseEnter(item.id)
              }}
              key={item.id}
              onClick={e => {
                return item.type === "collapse" ? e.preventDefault() : ""
              }}
              target={item.newTab ? "_blank" : undefined}
            >
              <div className="menu-text">
              {/* {item.icon} */}
                <img style={{marginRight: 3, paddingBottom: 5}} alt="icon" src={item.icon} width="25" height="25" />
                <span className="menu-item menu-title">{item.title}</span>
              </div>
  
              {item.badge ? (
                <div className="menu-badge">
                  <Badge color={item.badge} className="mr-1" pill>
                    {item.badgeText}
                  </Badge>
                </div>
              ) : (
                ""
              )}
              {item.type === "collapse" ? (
                <ChevronRight className="menu-toggle-icon" size={13} />
              ) : (
                ""
              )}
            </CustomAnchorTag>
  
            {item.type === "collapse" ? (
              <SideMenuGroup
                group={item}
                handleGroupClick={this.handleGroupClick}
                activeGroup={this.state.activeGroups}
                handleActiveItem={this.props.handleActiveItem}
                activeItemState={this.props.activeItemState}
                handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
                activePath={this.props.activePath}
                hoverIndex={this.props.hoverIndex}
                initRender={this.initRender}
                parentArr={this.parentArr}
                triggerActive={undefined}
                currentActiveGroup={this.state.currentActiveGroup}
                permission={this.props.permission}
                currentUser={this.props.currentUser}
                redirectUnauthorized={this.redirectUnauthorized}
                collapsedMenuPaths={this.props.collapsedMenuPaths}
                toggleMenu={this.props.toggleMenu}
                deviceWidth={this.props.deviceWidth}
              />
            ) : (
              ""
            )}
          </li>
          
        )
        
        if (
          item.navLink &&
          item.collapsed !== undefined &&
          item.collapsed === true
        ) {
          this.collapsedPath = item.navLink
          this.props.collapsedMenuPaths(item.navLink)
        }
  
        if (
          item.type === "collapse" || item.type === "external-link" ||
          (item.type === "item" &&
            item.permissions &&
            item.permissions.includes(this.props.currentUser)) ||
          item.permissions === undefined
        ) {
          return renderItem
        } else if (
          item.type === "item" &&
          item.navLink === this.props.activePath &&
          !item.permissions.includes(this.props.currentUser)
        ) {
          return this.redirectUnauthorized()
        }
        return null
       
      })
      return <React.Fragment>{menuItems}</React.Fragment>
    } else return null
    
  }
}

export default SideMenuContent
