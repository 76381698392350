import React from "react"
import { Navbar } from "reactstrap"
import classnames from "classnames"
import NavbarUser from "./NavbarUser"

const ThemeNavbar = props => {

  return (
    <>

    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        id="bg-color-custom"
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow", 
          "navbar-light static floating-nav"     
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper" >
                <h4 style={{color:"white"}} >
                {/* <strong>{process.env.REACT_APP_COMPANY}</strong> */}
                <strong>DASHBOARD</strong>
                </h4>
              </div>
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  </>
  )
}


export default ThemeNavbar
